import Storage, { EStorageType } from '@/helpers/storageHelper';

export enum ERegisterField {
  GGR = 'ggr',
  EMAIL = 'email',
  PASSWORD = 'password',
  ACTIVE_USERS = 'activeUsers',
  TOKEN_SYMBOL = 'tokenSymbol',
  BUSINESS_TYPE = 'businessType',
  BRAND_NAME = 'brandName',
  WEBSITE_URL = 'website'
}

export default new Storage<ERegisterField>(
  Object.values(ERegisterField).filter((i) => isNaN(+i)),
  EStorageType.SESSION
);
