import { OperatorOnboardingForm } from '@/api/schema';

import OnboardingValidationMixin from '@/mixins/Onboarding/OnboardingValidationMixin';
import { mapGetters, mapState } from 'vuex';
import { imageClickHandler } from '@/helpers/lightBoxHelper';

const defaultOnboardingForm: OperatorOnboardingForm = Object.freeze({
  ggr: '',
  name: '',
  test: true,
  tokenName: '',
  tokenNumber: 0,
  tokenPrice: 0,
  tokenTicker: '',
  url: ''
});

// export const monthlyGGRs = Object.freeze([
//   {
//     text: 'Less $500 000',
//     tokenPrice: 0.002,
//     tokenNumber: 1200000000
//   },
//   {
//     text: '$500 000 - $1 500 000',
//     tokenPrice: 0.004,
//     tokenNumber: 2500000000
//   },
//   {
//     text: '$1 500 000 - $3 000 000',
//     tokenPrice: 0.0075,
//     tokenNumber: 4000000000
//   },
//   {
//     text: 'Above $3 000 000',
//     tokenPrice: 0.01,
//     tokenNumber: 5000000000
//   }
// ]);

export const monthlyGGRs = Object.freeze([
  {
    text: 'Less $500 000',
    tokenPrice: 0.002,
    tokenNumber: 1200000000
  },
  {
    text: '$500 000 - $1 500 000',
    tokenPrice: 0.004,
    tokenNumber: 2500000000
  },
  {
    text: '$1 500 000 - $3 000 000',
    tokenPrice: 0.0075,
    tokenNumber: 4000000000
  },
  {
    text: 'Above $3 000 000',
    tokenPrice: 0.01,
    tokenNumber: 5000000000
  }
]);

export const monthlyActiveUsers = [
  'Less 500',
  '500 - 5000',
  '5000 - 15000',
  'Above 15000'
];

export default {
  mixins: [OnboardingValidationMixin],
  props: {
    value: {
      type: Object || undefined,
      default: undefined
    }
  },
  data: (): unknown => ({
    formData: { ...defaultOnboardingForm }
  }),
  computed: {
    ...mapState('Onboarding', ['onboardingProgress']),
    ...mapGetters('Onboarding', ['operatorProgress']),
    ggrItems(): string[] {
      return monthlyGGRs.map(({ text }) => text);
    },
    isExpanded: {
      get(): number | undefined {
        return this.value[this.onboardingStepName] ? 0 : undefined;
      },
      set(v: number | undefined): void {
        this.$emit('switchExpanded', this.onboardingStepName, v >= 0);
      }
    },
    isCompleted(): boolean {
      return this.operatorProgress[this.onboardingStepName];
    }
  },
  methods: {
    imageClickHandler,
    handlerSelectGGR(selectValue: string): void {
      const { tokenPrice, tokenNumber } = monthlyGGRs.find(
        ({ text }) => text === selectValue
      );

      this.formData = {
        ...this.formData,
        ...{
          ggr: selectValue,
          tokenNumber,
          tokenPrice
        }
      };
    }
  }
};
