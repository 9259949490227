



































const PASSWORD_LENGTH = 8;

import {
  passwordValidationMessage,
  noBlankSpacesAllowed,
  hasLowerAndUppercase,
  passwordValidator,
  hasDigits
} from '@/helpers/validation';
export default {
  name: 'PasswordInputExtended',
  props: {
    extended: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Password'
    },
    placeholder: {
      type: String,
      default: 'Password'
    }
  },
  data(): any {
    return {
      showPassword: false,
      validated: false,
      passwordRules: [(v) => passwordValidator(v, PASSWORD_LENGTH)]
    };
  },
  computed: {
    hasBlankSpace(): boolean {
      return noBlankSpacesAllowed(this.value);
    },
    validateOnBlur(): boolean {
      return this.extended ? !this.allPassed : false;
    },
    rules(): { label: string; isPassed: boolean; force?: boolean }[] {
      if (!this.extended) return [];

      return [
        {
          label:
            passwordValidationMessage.lengthWithoutBlankSpaces(
              PASSWORD_LENGTH
            ),
          isPassed:
            this.value?.length >= PASSWORD_LENGTH && !this.hasBlankSpace,
          force: this.hasBlankSpace
        },
        {
          label: passwordValidationMessage.digits,
          isPassed: hasDigits(this.value)
        },
        {
          label: passwordValidationMessage.lowerAndUpperCase,
          isPassed: hasLowerAndUppercase(this.value)
        }
      ];
    },
    allPassed(): boolean {
      return this.rules.every(({ isPassed }) => isPassed);
    }
  },
  methods: {
    handleBlur(): void {
      this.validated = true;
    },
    handleFocus(): void {
      this.validated = false;
    }
  }
};
