


















































































































































































import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import {
  monthlyGGRs,
  monthlyActiveUsers
} from '@/mixins/Onboarding/OnboardingMixin';
import OnboardingValidationMixin from '@/mixins/Onboarding/OnboardingValidationMixin';
import PasswordInputExtended from '@/components/PasswordInputExtended.vue';
import RegisterStorage, {
  ERegisterField
} from '@/views/AuthViews/registerStorage';
import debounce from 'lodash/debounce';
import {
  checkEmail,
  checkRegisterData,
  ESignUpType,
  signUpRequest,
  signUpVerify
} from '@/api/Auth';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { isEmail, required } from '@/helpers/validation';
import { formatBrandName } from '@/helpers/operatorHelpers';

import {
  isCopyStakeType,
  isSalesType,
  managerId
} from '@/helpers/authHelpers';

const storageWatchers = [
  ERegisterField.GGR,
  ERegisterField.BRAND_NAME,
  ERegisterField.TOKEN_SYMBOL,
  ERegisterField.ACTIVE_USERS,
  ERegisterField.PASSWORD,
  ERegisterField.EMAIL,
  ERegisterField.WEBSITE_URL
].reduce(
  (all, key) => ({ ...all, [key]: (val) => RegisterStorage.set(key, val) }),
  {}
);

const getActiveStep = () => {
  if (RegisterStorage.state.website && RegisterStorage.state.tokenSymbol) {
    if (RegisterStorage.state.activeUsers && RegisterStorage.state.ggr) {
      return 3;
    }

    return 2;
  }

  return 1;
};
export default {
  mixins: [OnboardingValidationMixin],
  components: {
    AuthLayout,
    PasswordInputExtended
  },
  data(): any {
    return {
      isSales: isSalesType,
      managerIdNumber: Number(managerId) || null,
      steps: ['Brand Token', 'Brand Info', 'Account Credential'],
      formValid: [false, false],
      activeStep: getActiveStep(),
      website: RegisterStorage.state.website || '',
      ggr: RegisterStorage.state.ggr || '',
      tokenSymbol: RegisterStorage.state.tokenSymbol || '',
      activeUsers: RegisterStorage.state.activeUsers || '',
      email: RegisterStorage.state.email || '',
      password: '',
      token: undefined,
      businessType: 'CASINO',
      userAgreement: false,
      emailRules: [(v) => required(v, 'E-mail'), isEmail],
      agreementRules: [(v) => !!v || 'You must agree to continue!'],
      signUpProcessing: false,
      showTooltip: {
        privacyPolicy: false,
        termsConditions: false,
        AmlKycPolicy: false
      },
      errors: {
        currency: null,
        email: null,
        website: null
      },
      mauList: monthlyActiveUsers,
      isLoading: false
    };
  },
  computed: {
    ggrItems(): string[] {
      return monthlyGGRs.map(({ text }) => text);
    },
    url: {
      get(): boolean {
        return this.website.replace('https://', '');
      },
      set(v: string): void {
        this.website = 'https://' + v.trim();
      }
    },
    brandName(): string {
      return formatBrandName(this.url);
    }
  },
  watch: {
    ...storageWatchers
  },
  created(): void {
    this.ggr = RegisterStorage.state.ggr || '';
  },
  mounted(): void {
    this.$nextTick(() => {
      if (this.activeStep > 1) this.checkValidSavedStorageData();
    });
  },
  methods: {
    async signUp(): Promise<void> {
      if (this.token) {
        this.signUpProcessing = true;
        try {
          const response = await signUpVerify(this.token, false);
          await this.$store.dispatch('Auth/authorize', response.token);
          await this.$router.push('/home');
          this.$toast.success('Login complete.');
        } catch (e) {
          errorToastMessage(e);
        } finally {
          this.signUpProcessing = false;
        }
      }
    },
    checkValidSavedStorageData(): void {
      if (!this.formValid[0]) {
        this.activeStep = 1;
      } else {
        this.isLoading = true;

        const promises = [
          this.asyncValidate('website', this.website),
          this.asyncValidate('currency', this.tokenSymbol)
        ];

        Promise.all(promises).then((): void => {
          this.isLoading = false;
          const invalidFormIndex = this.formValid.indexOf(false) + 1;

          if (invalidFormIndex > 0) this.activeStep = invalidFormIndex;
        });
      }
    },
    handleNext(): void {
      if (this.activeStep === 1) {
        window.dataLayer.push({
          event: 'registration_token_info_form',
          eventCategory: 'registration_flow',
          eventAction: 'submit',
          eventLabel: 'token_info_form'
        });
      }
      if (this.activeStep === 2) {
        window.dataLayer.push({
          event: 'registration_brand_info_form',
          eventCategory: 'registration_flow',
          eventAction: 'submit',
          eventLabel: 'brand_info_form'
        });
      }
      if (this.activeStep === 3) {
        window.dataLayer.push({
          event: 'registration_account_datails_form',
          eventCategory: 'registration_flow',
          eventAction: 'submit',
          eventLabel: 'account_datails_form'
        });
      }

      if (this.activeStep === 3) {
        this.createAccount();
      } else {
        this.activeStep += 1;
      }
    },
    handlePrev(): void {
      if (this.activeStep < 2) return;

      this.activeStep -= 1;
    },
    createAccount(): void {
      const { tokenNumber, tokenPrice } = monthlyGGRs.find(
        ({ text }) => text === this.ggr
      );

      const payload = {
        operatorName: `${this.brandName}`,
        tokenName: `${this.brandName} Token`,
        brandName: this.brandName,
        website: this.website,
        tokenSymbol: this.tokenSymbol,
        ggr: this.ggr,
        businessType: this.businessType,
        activeUsers: this.activeUsers,
        email: this.email,
        requestType: isSalesType ? ESignUpType.SALE : ESignUpType.DEFAULT,
        tokenNumber,
        tokenPrice,
        copyStakeAccount: isCopyStakeType,
        ...(isSalesType ? {} : { password: this.password }),
        ...(isSalesType && this.managerIdNumber
          ? { managerId: this.managerIdNumber }
          : {})
      };

      signUpRequest(payload)
        .then((data) => {
          this.activeStep = 4;
          RegisterStorage.clear();
          this.$toast.success('Registration completed');
          this.token = data.token;
        })
        .catch(errorToastMessage);
    },
    checkAllValid(type: string, val: string): boolean {
      const mapRulesKeyType = {
        currency: 'tokenTickerRules',
        website: 'websiteUrl'
      };
      const validationFunctions = this.rules[mapRulesKeyType[type]];

      const isAllValid = validationFunctions.every((validationFunction) => {
        const validationResult = validationFunction(val);
        return validationResult === true || validationResult === '';
      });

      if (!isAllValid) this.clearErrorMessage(type);
      return isAllValid;
    },
    asyncValidate(type: string, val: string): Promise<any> {
      if (!val || !this.checkAllValid(type, val)) return;

      return checkRegisterData(type)(val)
        .then((valid) => {
          if (!valid) {
            this.errors[type] = 'Already used';
          } else {
            this.clearErrorMessage(type);
          }
        })
        .catch(checkRegisterData);
    },
    asyncValidateDebounced: debounce(function (type: string, value: string) {
      this.asyncValidate(type, value);
    }, 300) as (type: string, value: string) => void,
    clearErrorMessage(field: string): void {
      this.errors[field] = null;
    },
    validateEmail(): Promise<void> {
      if (typeof isEmail(this.email) === 'string') return;

      return checkEmail(this.email).then((valid) => {
        if (valid) {
          this.errors.email = null;
        } else {
          this.errors.email = 'E-mail already used';
        }
      });
    },
    resetRefValidation(value: string, refName: string): void {
      const str = value.toString();

      if (str?.length === 0) this.$refs?.[refName]?.resetValidation();
    }
  }
};
