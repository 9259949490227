export enum EStorageType {
  SESSION = 'session',
  LOCAL = 'local'
}

interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

const getStorage = (type: EStorageType): IStorage => {
  const storageType = `${type}Storage`;

  return window[storageType] || window.localStorage;
};

export default class Storage<T extends string> {
  private readonly storage: IStorage;
  private readonly keys: T[];

  public constructor(keys: T[], type: EStorageType = EStorageType.LOCAL) {
    this.storage = getStorage(type);
    this.keys = keys;
  }

  get state(): Record<T, string | null> {
    return this.keys.reduce((state, key) => {
      const value = this.get(key);

      return value ? { ...state, [key]: value } : state;
    }, {} as Record<T, string | null>);
  }

  get(key: T): string | null {
    return this.storage.getItem(key);
  }

  set(key: T, value: string): void {
    this.storage.setItem(key, value);
  }

  remove(key: T): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.keys.forEach((k) => this.remove(k));
  }
}
